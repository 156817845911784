import angular from 'angular';
import ngclipboard from 'ngclipboard';

import shellAppLibrary from './app-library';
import shellAppTypes from './app-types';
import shellHome from './home';

import shellUsers from './users';
import shellSubjectsMethods from './subjects-methods';
import klopotekImport from './klopotek-import';
import shellLicenseSettings from './license-settings';
import shellTableOfContent from './table-of-content';
import shellTags from './tags';
import shellMediaContentTypes from './media-content-types';
import shellMediaLinks from './media-links';
import shellExerciseCategories from './exercise-categories';
import shellExtensions from './extensions';
import shellStatistics from './statistics';
import shellExports from './exports';
import shellFaq from './faq';
import shellAuthors from './authors';
import shellMarketingTools from './marketing-tools';
import shellMiniDialogManager from './mini-dialog-manager';
import shellWordTrainerManager from './word-trainer-manager';

const module = angular.module('app.shell.modules', [
    shellHome,
    shellUsers,
    shellSubjectsMethods,
    klopotekImport,
    shellAppLibrary,
    shellAppTypes,
    shellLicenseSettings,
    shellTableOfContent,
    shellTags,
    shellMediaContentTypes,
    shellMediaLinks,
    shellExerciseCategories,
    shellFaq,
    shellExtensions,
    shellStatistics,
    shellExports,
    shellAuthors,
    shellMarketingTools,
    shellMiniDialogManager,
    shellWordTrainerManager,
    ngclipboard,
]);

export default module.name;
