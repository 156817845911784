class Sidebar {
    // @ngInject
    constructor($state, permissionService) {
        this.state = $state;
        this.current = $state.current.name;
        this.permissions = permissionService;
    }

}

module.exports = {
    template: `
        <div class="col-sm-3 col-md-2 sidebar">
            <div class="sidebar__group" ng-if="$ctrl.permissions.canManageKlopotekImports || $ctrl.permissions.canManageExtensions || $ctrl.permissions.canManageFaq || $ctrl.permissions.canManageUsers || $ctrl.permissions.canManageStatistics || $ctrl.permissions.canManageExports || $ctrl.permissions.canManageAuthors">
                <p class="nav__title">System</p>
                 <ul class="nav nav-sidebar">
                    <sidebar-menu-item state="'klopotek-import'" title="'Klopotek Import'" ng-if="$ctrl.permissions.canManageKlopotekImports"></sidebar-menu-item>
                    <sidebar-menu-item state="'extensions'" title="'Extensions'" ng-if="$ctrl.permissions.canManageExtensions"></sidebar-menu-item>
                    <sidebar-menu-item state="'faq'" title="'Info & Help'" ng-if="$ctrl.permissions.canManageFaq"></sidebar-menu-item>
                    <sidebar-menu-item state="'users'" title="'Users'" ng-if="$ctrl.permissions.canManageUsers"></sidebar-menu-item>
                    <sidebar-menu-item state="'statistics'" title="'Statistics'" ng-if="$ctrl.permissions.canManageStatistics"></sidebar-menu-item>
                    <sidebar-menu-item state="'exports'" title="'Exports'" ng-if="$ctrl.permissions.canManageExports"></sidebar-menu-item>
                    <sidebar-menu-item state="'authors'" title="'Authors'" ng-if="$ctrl.permissions.canManageAuthors"></sidebar-menu-item>
                    <sidebar-menu-item state="'marketing-tools'" title="'Marketing tools'" ng-if="$ctrl.permissions.canManageMarketingTools"></sidebar-menu-item>
                </ul>
            </div>

            <div class="sidebar__group" ng-if="$ctrl.permissions.canManageSubjectsAndMethods || $ctrl.permissions.canManageTableOfContent || $ctrl.permissions.canManageExerciseCategories">
                <p class="nav__title">Hierarchy</p>
                <ul class="nav nav-sidebar">
                    <sidebar-menu-item state="'subjects-methods'" title="'Subjects and Methods'" ng-if="$ctrl.permissions.canManageSubjectsAndMethods"></sidebar-menu-item>
                    <sidebar-menu-item state="'table-of-content'" title="'Module table of contents'" ng-if="$ctrl.permissions.canManageTableOfContent"></sidebar-menu-item>
                    <sidebar-menu-item state="'exercise-categories'" title="'Exercise Categories'" ng-if="$ctrl.permissions.canManageExerciseCategories"></sidebar-menu-item>
                </ul>
            </div>

            <div class="sidebar__group" ng-if="$ctrl.permissions.canManageAppTypes || $ctrl.permissions.canManageApps || $ctrl.permissions.canManageMediaContentTypes || $ctrl.permissions.canManageTags || $ctrl.permissions.canManageMediaLinks  ||  $ctrl.permissions.canManageMiniDialogs || $ctrl.permissions.canManageWordLists">
                <p class="nav__title">Content</p>
                <ul class="nav nav-sidebar">
                    <sidebar-menu-item state="'app-types'" title="'App types'" ng-if="$ctrl.permissions.canManageAppTypes"></sidebar-menu-item>
                    <sidebar-menu-item state="'app-library'" title="'App library'" ng-if="$ctrl.permissions.canManageApps"></sidebar-menu-item>
                    <sidebar-menu-item state="'media-content-types'" title="'Media link content types'" ng-if="$ctrl.permissions.canManageMediaContentTypes"></sidebar-menu-item>
                    <sidebar-menu-item state="'tags'" title="'Method specific tags'" ng-if="$ctrl.permissions.canManageTags"></sidebar-menu-item>
                    <sidebar-menu-item state="'media-links'" title="'Media links'" ng-if="$ctrl.permissions.canManageMediaLinks"></sidebar-menu-item>
                    <sidebar-menu-item state="'mini-dialog-manager'" title="'Dialogue manager'" ng-if="$ctrl.permissions.canManageMiniDialogs"></sidebar-menu-item>
                    <sidebar-menu-item state="'word-trainer-manager'" title="'Word Trainer manager'" ng-if="$ctrl.permissions.canManageWordLists"></sidebar-menu-item>
                </ul>
            </div>

            <div class="sidebar__group" ng-if="$ctrl.permissions.canManageLicenseSettings">
                <p class="nav__title">Licenses</p>
                <ul class="nav nav-sidebar sidebar-menu">
                    <sidebar-menu-item state="'licenseSettings'" title="'License Settings'" ng-if="$ctrl.permissions.canManageLicenseSettings"></sidebar-menu-item>
                </ul>
            </div>
        </div>
    `,
    controller: Sidebar,
};
