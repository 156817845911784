import angular from 'angular';
import uiRouter from 'angular-ui-router';
import toaster from 'angularjs-toaster';
import uiBootstrap from 'angular-ui-bootstrap';
import modelFactory from 'angular-model-factory';
import 'checklist-model';
import 'babel-polyfill';
import ngDialog from 'ng-dialog';
import ngCookies from 'angular-cookies';
import angularJwt from 'angular-jwt';
import storage from 'angular-storage';
import ngMessages from 'angular-messages';
import 'angular-bootstrap-colorpicker';
import 'angular-file-upload';
import 'ng-focus-if';

import '@iamadamjowett/angular-click-outside';

import 'angularjs-bootstrap-datetimepicker/src/js/datetimepicker';
import 'angularjs-bootstrap-datetimepicker/src/js/datetimepicker.templates';
import 'angular-date-time-input/src/dateTimeInput';

import 'angular-marked';
import 'angular-ui-tree';
import 'angular-ui-tree-filter/dist/angular-ui-tree-filter';
import angularUiSelect from 'ui-select';

import 'angular-multiselect/dist/multiselect-tpls';

import ngSanitize from 'angular-sanitize'; // we need sanitize for the autocomplete
import 'angular-auto-complete';
import angularFilter from 'angular-filter';
import 'chart.js';
import 'angular-chart.js';

import 'angular-debounce';

import common from '../common';
import shell from './shell';

import naturalSort from '../common/services/natural-sort-version';

import {
    routing,
    errorHandler,
    modelFactoryConfig,
    ngDialogConfig,
    fileUploaderConfig,
    hashPrefixConfig,
    storageProviderConfig,
    interceptors,
} from './app.config';

import {
    refreshTokenConfig,
    imgSrcSanitizationWhitelistConfig,
} from '../common/config/app.config';

import startup from './app.startup';

import './index.scss';

function ngDialogConfigInTest(ngDialogProvider) {
    ngDialogProvider.setDefaults({
        className: 'ngdialog-theme-default',
        closeByNavigation: true,
        disableAnimation: true,
    });

    // DO NOT ADD OR UNCOMMENT THE FOLLOWING LINE , A LOT OF ISSUES WILL BE CAUSED BY THIS  SEE [PAKP-1052] !!!
    //ngDialogProvider.setOpenOnePerName(true);
}

const module = angular
    .module('app', [
        // 3th party
        uiRouter,
        toaster,
        uiBootstrap,
        modelFactory.name,
        ngDialog,
        ngCookies,
        storage,
        'checklist-model',
        angularJwt,
        ngMessages,
        'colorpicker.module',
        'angularFileUpload',
        'hc.marked',
        'ui.bootstrap.datetimepicker',
        'ui.dateTimeInput',
        angularUiSelect,
        'ui.tree',
        'ui.tree-filter',
        'am.multiselect',

        'angular-click-outside',

        'debounce',

        ngSanitize,
        'autoCompleteModule',
        naturalSort,
        angularFilter,
        'chart.js',
        'focus-if',

        // application
        common,
        shell,
    ]);

// constants
module
    .constant('config', {
        version: VERSION,
        env: ENVIRONMENT,
        baseUrl: ENVIRONMENT === 'test' ? '/api' : `${PORTAAL_API_URL || ''}/api`,
        studioUrl: PORTAAL_URL || 'http://localhost:8000',
    })
    .constant('targetLanguage', {
        DUTCH: 0,
        FRENCH: 1,
        DUTCH_AND_FRENCH: 2,
    })
    .constant('lessonDisplays', {
        NEUTRAL: 'neutral',
        BIG_NUMBER: 'big_number',
        BIG_TITLE: 'big_title',
    })
    .constant('reviewStates', {
        NOT_IN_REVIEW: 0,
        IN_REVIEW: 1,
        ACCEPTED: 2,
        DECLINED: 3,
    })
    .constant('states', {
        DRAFT: 'draft',
        PREVIEW: 'preview',
        PUBLISHED: 'published',
    })
    .constant('subjectPublishingStates', {
        DRAFT: 'draft',
        REVIEW: 'review',
        PUBLISHED: 'published',
    })
    .constant('methodPublishingStates', {
        DRAFT: 'draft',
        REVIEW: 'review',
        PUBLISHED: 'published',
    })
    .constant('modulePublishingStates', {
        DRAFT: 'draft',
        REVIEW: 'review',
        PREVIEW: 'preview',
        PUBLISHED: 'published',
        PHASED_OUT: 'phasedOut',
        ARCHIVED: 'archived',
    })
    .constant('appPublishingStates', {
        DRAFT: 'draft',
        REVIEW: 'review',
        PREVIEW: 'preview',
        PUBLISHED: 'published',
    })
    .constant('wordListType', {
        SINGLE: 'single',
        COMPILED: 'compiled',
    })
    .constant('platforms', {
        GOOGLE: 'Android',
        APPLE: 'iOs',
        WEB_INTERN: 'Web intern',
        WEB_EXTERN: 'Web extern',
        P_INTERN: 'P intern',
    })
    .constant('educationalNetworks', [
        'GO',
        'KO',
        'OVSG',
        'RESEAU_OFFICIEL',
        'RESEAU_LIBRE',
    ])
    .constant('educationalTypes', {
        A_STROOM: 'a-stroom',
        B_STROOM: 'b-stroom',
        ASO: 'ASO',
        TSO: 'TSO',
        BSO: 'BSO',
        KSO: 'KSO',
        LAGER_ONDERWIJS: 'Lager onderwijs',
        KLEUTERONDERWIJS: 'Kleuteronderwijs',
        VOLWASSENENONDERWIJS: 'Volwassenenonderwijs',
    })
    .constant('faqType', {
        FAQ: 0,
        TUTORIAL: 1,
    })
    .constant('studioGroup', {
        SO: 'VLAANDEREN',
        ES: 'WALLONIË',
        BAO: 'BASIS',
        VO: 'VLAANDEREN',
    })
    .constant('moduleType', {
        SUB: 'SUB',
        SUPER: 'SUPER',
    })
    .constant('linkedAppType', {
        WORDLIST: 'WordList',
        DIGIBOOK: 'digibook',
        MEDIALINK: 'MediaLink',
    })
    .constant('lessonSorting', {
        CATEGORY: 'category',
        LESSON_ORDER: 'lesson_order',
    })
    .config(modelFactoryConfig)
    .config(imgSrcSanitizationWhitelistConfig)
    .config(hashPrefixConfig);

if (ENVIRONMENT !== 'test') {

    // config
    module.config(routing)
        .config(refreshTokenConfig)
        .config(errorHandler)
        .config(ngDialogConfig)
        .config(fileUploaderConfig)
        .config(storageProviderConfig)
        .config(interceptors);

    // initial startup
    module.run(startup);
} else {
    module.config(ngDialogConfigInTest);
}
