import { AxiosPromise } from 'axios';
import shellApi from '../services/api';
import MedialinkKind from '../modules/media-links/utitilies/medialink-kind';

export type Schedule = {
    id: string;
    name: string;
};

export type AssessmentQExercisePublication = {
    id: string;
    name: string;
};

export type Publication = AssessmentQExercisePublication & {
    isAdaptive: boolean;
};

export type Part = {
    id: string;
    name: string;
    nodes: Part[];
    fullPath?: string;
};

export type BaseAssessmentQExercise = {
    showScore: boolean;
    publication: AssessmentQExercisePublication;
    schedule: Schedule;
};

export type AssessmentQExercise = BaseAssessmentQExercise & {
    isAdaptive: boolean;
};

export type EdumaticExercise = BaseAssessmentQExercise & {
    publicationPart: Part;
};

export type Exercise = {
    name: string;
} & ({
    kind: typeof MedialinkKind.EXERCISE_ASSESSMENTQ;
    assessmentQExercise: AssessmentQExercise;
} | {
    kind: typeof MedialinkKind.EXERCISE_EDUMATIC;
    edumaticExercise: EdumaticExercise;
});

export type AssessmentQExercisePostRequestBody = Exercise[];

export const getSchedules = async (): Promise<Schedule[]> => {
    const {
        data: { data },
    } = await shellApi.get('/shell/assessmentq/schedules');

    return data;
};

export const getPublications = async (scheduleId: string): Promise<Publication[]> => {
    const {
        data: { data },
    } = await shellApi.get(`/shell/assessmentq/schedules/${scheduleId}/publications`);

    return data;
};

export const getParts = async (publicationId: string): Promise<Part[]> => {
    const {
        data: { data },
    } = await shellApi.get(`/shell/assessmentq/publications/${publicationId}/parts`);

    return data;
};

export const postAssessmentQExercises = async (moduleId: string, nodeId: string, assessmentQExercises: AssessmentQExercisePostRequestBody): Promise<AxiosPromise> => {
    const response = await shellApi.post(`/shell/modules/${moduleId}/table-of-content/${nodeId}/medialinks/assessmentq`, assessmentQExercises);

    return response;
};

export const patchAssessmentQExercises = async (moduleId: string, nodeId: string, id: string, payload: { name: string; showScore: boolean; }): Promise<AxiosPromise> => {
    const response = await shellApi.patch(`/shell/modules/${moduleId}/table-of-content/${nodeId}/medialinks/assessmentq/${id}`, payload);

    return response;
};
