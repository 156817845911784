import React, { useEffect, useState } from 'react';
import {
    AssessmentQExercisePostRequestBody,
    getParts,
    patchAssessmentQExercises,
    Part, postAssessmentQExercises,
    EdumaticExercise,
} from '../../../../api/MediaLinksApi';
import Tree from './components/Tree/Tree';
import { findPartsByIds } from './components/Tree/utils';
import Schedules from './components/Schedules/Schedules';
import Publications from './components/Publications/Publications';
import MedialinkKind from '../../utitilies/medialink-kind';

export type MediaLink = {
    name: string;
    id: string;
    createdAt: string;
    kind: typeof MedialinkKind.EXERCISE_EDUMATIC;
    edumaticExercise: EdumaticExercise;
};

type Props = {
    onClose: () => void;
    medialinks: MediaLink[];
    onSave: () => void;
    moduleId: string;
    nodeId: string;
    medialink?: MediaLink;
};

const EdumaticSettings = ({
    onClose, onSave, moduleId, nodeId, medialink, medialinks,
}: Props): JSX.Element => {
    const isEditing = Boolean(medialink?.id);
    const [error, setError] = useState<string | null>(null);

    const [selectedSchedule, setSelectedSchedule] = useState<{ value: string; label: string; } | null>(isEditing && medialink ? {
        value: medialink.edumaticExercise.schedule.id,
        label: medialink.edumaticExercise.schedule.name,
    } : null);
    const [selectedPublication, setSelectedPublication] = useState<{ value: string; label: string; } | null>(medialink?.edumaticExercise.publication ? {
        value: medialink.edumaticExercise.publication.id,
        label: medialink.edumaticExercise.publication.name,
    } : null);
    const [parts, setParts] = useState<Part[]>([]);
    const [selectedPartIds, setSelectedPartIds] = useState<string[]>([]);
    const [name, setName] = useState<string | undefined>(medialink?.name || undefined);
    const [showScore, setShowScore] = useState<boolean>(medialink?.edumaticExercise?.showScore || false);

    useEffect(() => {
        if (!selectedPublication) return;
        const fetchParts = async () => {
            setParts(await getParts(selectedPublication.value));
        };
        fetchParts();
    }, [selectedPublication]);

    const handleNodeChange = (id: string) => {
        setSelectedPartIds(prevState => {
            if (prevState.includes(id)) return prevState.filter(partId => partId !== id);
            return [...prevState, id];
        });
    };

    const handleSubmit = async () => {
        if (!selectedPublication || !selectedSchedule) return;

        const selectedParts = findPartsByIds(parts, selectedPartIds);

        const exercises: AssessmentQExercisePostRequestBody = selectedParts.map(part => ({
            name: part.name,
            kind: MedialinkKind.EXERCISE_EDUMATIC,
            edumaticExercise: {
                showScore,
                publication: {
                    id: selectedPublication.value,
                    name: selectedPublication.label,
                },
                schedule: {
                    id: selectedSchedule.value,
                    name: selectedSchedule.label,
                },
                publicationPart: part,
            },
        }));

        try {
            if (medialink?.id && name) {
                await patchAssessmentQExercises(moduleId, nodeId, medialink.id, { name, showScore });
            } else {
                await postAssessmentQExercises(moduleId, nodeId, exercises);
            }
            onSave();
        } catch (e) {
            if (isEditing) {
                setError('Something went wrong.');
            } else {
                setError('Something went wrong, no AssessmentQ medialinks have been created.');
            }
        }
    };

    const handleScheduleChange = React.useCallback(selectedOption => {
        setSelectedSchedule(selectedOption);

        setParts([]);
    }, []);

    return (
        <form noValidate className="form-horizontal assessment-q-settings">
            <div className="panel panel-default">
                <div className="panel-heading">
                    <h3 className="panel-title">Import Exercise publications</h3>
                </div>
                <div className="panel-body">
                    <div className="col-sm-12">
                        {isEditing && (
                            <div className="form-group">
                                <label htmlFor="name" className="control-label col-md-4">Name</label>
                                <div className="col-md-7">
                                    <input
                                        id="name"
                                        name="name"
                                        className="form-control col-md-4"
                                        type="text"
                                        required
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                        <Schedules
                            medialinks={medialinks}
                            disabled={isEditing}
                            selectedSchedule={selectedSchedule}
                            onChange={handleScheduleChange}
                        />
                        <Publications
                            disabled={!selectedSchedule || isEditing}
                            selectedScheduleId={selectedSchedule?.value}
                            selectedPublication={selectedPublication}
                            onChange={selectedOption => {
                                setSelectedPublication(selectedOption);
                            }}
                        />
                        <Tree data={parts} onSelect={handleNodeChange} selectedParts={medialink?.edumaticExercise?.publicationPart?.id} />
                        <div className="form-group">
                            <label htmlFor="showScore" className="control-label col-md-4">Show score</label>
                            <div className="col-md-7">
                                <input type="checkbox" id="showScore" onChange={e => setShowScore(e.target.checked)} defaultChecked={showScore} />
                            </div>
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                </div>
                <div className="panel-footer">
                    <div className="ngdialog-buttons">
                        <button onClick={onClose} type="button" className="btn ngdialog-button ngdialog-button-secondary">
                            Cancel
                        </button>
                        <button type="button" disabled={selectedPartIds.length === 0 || Boolean(medialink?.id && name?.length === 0)} className="btn ngdialog-button ngdialog-button-primary" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EdumaticSettings;
