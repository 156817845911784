import Role from '../../../core/security/role';

export default class PermissionService {

    // @ngInject
    constructor($rootScope) {
        this.$rootScope = $rootScope;
    }

    get canAccessHome() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN, Role.EDITOR, Role.PRODUCTION, Role.EXTERNAL]);
    }

    get canManageKlopotekImports() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN, Role.PRODUCTION]);
    }

    get canManageExtensions() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN]);
    }

    get canManageExports() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN, Role.PRODUCTION]);
    }

    get canManageFaq() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN]);
    }

    get canManageUsers() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN, Role.PRODUCTION]);
    }

    get canManageMiniDialogs() {
        return this.currentIdentity.hasAnyRole([Role.EXTERNAL, Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageWordLists() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN, Role.EXTERNAL]);
    }

    get canManageSubjectsAndMethods() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageTableOfContent() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageExerciseCategories() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageAppTypes() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN]);
    }

    get canManageApps() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageMediaContentTypes() {
        return this.currentIdentity.hasAnyRole([
            Role.PRODUCTION,
            Role.SYSADMIN,
        ]);
    }

    get canManageTags() {
        return this.currentIdentity.hasAnyRole([
            Role.PRODUCTION,
            Role.SYSADMIN,
        ]);
    }

    get canManageMediaLinks() {
        return this.currentIdentity.hasAnyRole([Role.EDITOR, Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageLicenseSettings() {
        return this.currentIdentity.hasAnyRole([Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageStatistics() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN]);
    }

    get canManageAuthors() {
        return this.currentIdentity.hasAnyRole([Role.EDITOR, Role.PRODUCTION, Role.SYSADMIN]);
    }

    get canManageMarketingTools() {
        return this.currentIdentity.hasAnyRole([Role.SYSADMIN]);
    }

    get currentIdentity() {
        return this.$rootScope.identity;
    }
}
