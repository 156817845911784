import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { getSchedules, Schedule } from '../../../../../../api/MediaLinksApi';
import type { MediaLink } from '../../AssessmentQSettings';

type Props = {
    disabled: boolean;
    selectedSchedule?: { value: string; label: string; } | null;
    onChange: (value: SingleValue<{ value: string; label: string; }>) => void;
    medialinks: MediaLink[];
};

const Schedules = ({
    disabled, selectedSchedule, onChange, medialinks,
}: Props): JSX.Element => {
    const [schedules, setSchedules] = useState<Schedule[]>([]);

    useEffect(() => {
        const fetchSchedules = async () => {
            setSchedules(await getSchedules());
        };
        fetchSchedules();
    }, []);

    useEffect(() => {
        if (!schedules.length || !medialinks.length) return;

        const lastEdumaticMedialink = medialinks.filter(medialink => !!medialink.assessmentQExercise).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
        if (!lastEdumaticMedialink) return;

        const defaultSchedule = schedules.find(schedule => schedule.id === lastEdumaticMedialink.assessmentQExercise?.schedule.id);
        if (!defaultSchedule) return;

        onChange({
            value: defaultSchedule.id,
            label: defaultSchedule.name,
        });

    }, [onChange, medialinks, schedules]);

    return (
        <div className="form-group" data-testid="schedules">
            <label htmlFor="schedules" className="control-label col-md-4">Schedules</label>
            <div className="col-md-7">
                <Select
                    inputId="schedules"
                    options={schedules.map(schedule => ({
                        value: schedule.id,
                        label: schedule.name,
                    }))}
                    onChange={onChange}
                    value={selectedSchedule}
                    placeholder="Select a schedule"
                    styles={{
                        container: baseStyles => ({
                            ...baseStyles,
                            display: 'flex',
                        }),
                        control: baseStyles => ({
                            ...baseStyles,
                            width: '100%',
                        }),
                    }}
                    isDisabled={disabled}
                />
            </div>
        </div>
    );
};

export default Schedules;
