import slug from '../../../../../core/slug';
import subjectSettingsTemplate from '../subject-settings.html';

class Subject {

    // @ngInject
    constructor(SubjectModel, UserModel, ngDialog, $scope, subjectPublishingStates, $q, toaster) {

        this.SubjectModel = SubjectModel;
        this.UserModel = UserModel;
        this.ngDialog = ngDialog;
        this.$scope = $scope;
        this.states = subjectPublishingStates;
        this.$q = $q;
        this.toaster = toaster;

        this.subjects = [];
    }

    $onChanges(context) {

        if (context.publishingHouses) {
            if (context.publishingHouses.currentValue && context.publishingHouses.currentValue.length > 0) {
                this.selectedPublishingHouse = context.publishingHouses.currentValue[0];

                return this.$q.all([
                    this.getSubjects(),
                    this.getResponsiblePersons(),
                ]);
            }
        }
    }

    getSubjects() {
        const query = {
            publishingHouseId: this.selectedPublishingHouse.id,
            orderBy: 'name',
        };

        return this.SubjectModel
            .query(query)
            .then(subjects => {
                this.subjects = subjects;
                this.subjectsLoaded({ subjects });
            });
    }

    getResponsiblePersons() {
        return this.UserModel
            .queryEditors()
            .then(responsibles => {
                this.responsiblePersons = responsibles;
            });
    }

    onPublishingHouseChanged() {
        this.getSubjects();
    }

    openDetails(subject) {
        // keep reference to subject that we want to edit so we can update this subject after save with the new value
        this.subjectToEdit = subject;

        // if no subject, create new with default state to draft and default publishinghouse (if selected)
        // otherwise take copy so our view does not gets updated automatically
        this.currentSubject = subject ? subject.$copy() : new this.SubjectModel({
            publishingState: 'draft',
        });

        if (!subject && this.selectedPublishingHouse) {
            this.currentSubject.publishingHouseId = this.selectedPublishingHouse.id;
        }

        return this.ngDialog.open({
            template: subjectSettingsTemplate,
            plain: true,
            scope: this.$scope,
            className: 'ngdialog-theme-default subject-settings__modal',
            preCloseCallback: value => {
                if (value) {
                    this.currentSubject = null;
                }
            },
        });
    }

    onSubjectClicked(subject) {
        this.selectedSubject = subject;
        this.subjectChanged({ subject });
    }

    createSlug(subject) {
        if (subject && subject.name && !subject.slug) {
            subject.slug = slug(subject.name);
        }
    }

    saveSubject(valid) {
        if (!valid) return;

        const isNew = angular.isUndefined(this.currentSubject.id);

        // when the publishing house was changed during edit, keep index so we can remove it later from the list
        let index;
        if (this.currentSubject.$diff()) {
            this.currentSubject.$diff().forEach(el => {
                if (el.path[0] === 'publishingHouseId') {
                    this.subjects.forEach((val, key) => {
                        if (val.id === this.currentSubject.id) index = key;
                    });
                }
            });
        }

        this.currentSubject.$save()
            .then(subject => {
                if (isNew) {
                    this.subjects.push(subject);
                } else {
                    this.subjectToEdit.$update(subject);
                    this.subjectToEdit.$commit();
                    if (index !== undefined && this.selectedPublishingHouse) {
                        this.subjects.splice(index, 1);
                    }
                }

                return this.ngDialog.close();
            });
    }

    deleteSubject(subject) {
        return subject.$destroy()
            .then(sub => {
                const index = this.subjects.map(x => x.id).indexOf(sub.id);
                if (index > -1) this.subjects.splice(index, 1);
                return this.ngDialog.close();
            })
            .catch(err => {
                if (err.status === 409) {
                    this.toaster.pop('error', err.data.message);
                }
            });
    }
}

export default {
    template: `
    <div class="panel panel-default">
    <div class="panel-heading">
        <div class="row">
            <h3 class="panel-title col-sm-4 subjects-methods-component__title">Subjects</h3>
            <div class="col-sm-8">
                <select id="selectedPublishingHouseId"
                        class="form-control form-inline subjects-methods-component__filter qs-publishinghouse-filter"
                        ng-model="$ctrl.selectedPublishingHouse"
                        ng-options="pubHouse.name group by pubHouse.groupName for pubHouse in $ctrl.publishingHouses track by pubHouse.id"
                        ng-change="$ctrl.onPublishingHouseChanged()">
                </select>
            </div>
        </div>
    </div>
    <div class="panel-body subject-list">
        <div class="row subject-list__no-result qs-subject-list__no-result" ng-if="!$ctrl.subjects || $ctrl.subjects.length === 0">
            <span class="text-muted">No matching subjects found</span>
        </div>
        <div class="row subject-list__item qs-subject-list__item"
             ng-repeat="(key, subject) in $ctrl.subjects"
             ng-class="{'subject-list__item--active': $ctrl.selectedSubject.id === subject.id}"
        >
            <div class="col-xs-11 subject-item__info subject-item__col"
                 ng-click="$ctrl.onSubjectClicked(subject)">
                <div class="col-xs-7 qs-subject-item__name">{{subject.name}} <small class="text-muted subject-info__shortName">({{subject.shortName}})</small></div>
                <div class="col-xs-5 qs-subject-item__publishingstate">
                    <publishing-state-component class="pull-right" publishing-state="subject.publishingState"></publishing-state-component>
                </div>
            </div>

            <div class="col-xs-1 pull-right subject-list__actions subject-item__col">
                <span class="glyphicon glyphicon-cog subjects-methods__action--edit qs-subject__edit" ng-click="$ctrl.openDetails(subject)"></span>
            </div>
        </div>
    </div>
    <div class="panel-footer clearfix">

        <button class="btn btn-success col-xs-12 qs-subject__manually" ng-click="$ctrl.openDetails()"><i
                class="glyphicon glyphicon-plus"></i> Add subject manually
        </button>

        <p class="text-muted small subjects-methods-footer__note">
            NOTE: Please try to use the klopotek import function before adding a subject manually
        </p>

    </div>
</div>
    `,
    controller: Subject,
    bindings: {
        publishingHouses: '<',
        selectedSubject: '<',
        subjectsLoaded: '&',
        subjectChanged: '&',
    },
};
